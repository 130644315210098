import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const Guest = () => {
  const navigate = useNavigate();
  return (
    <div className="guest_wrap">
      <div style={{ marginBottom: 12 }}>Guest user has limited access</div>
      <Button
        color="darker"
        variant="contained"
        sx={{ bgcolor: "white", color: "black", borderRadius: 40, fontWeight: 600, fontSize: 16 }}
        onClick={() => navigate("/sign-up")}
      >
        Sign up for full access
      </Button>
    </div>
  );
};
