import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GoogleLogin from "react-google-login";
import AppleLogin from "react-apple-login";

import { Typography, TypographyProps, useTheme, Divider } from "@mui/material";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { ReactComponent as GoogleIcon } from "../../assets/svgs/auth/google.svg";
import { ReactComponent as AppleIcon } from "../../assets/svgs/auth/apple.svg";
import { ReactComponent as WalletIcon } from "../../assets/svgs/auth/wallet.svg";
import { ReactComponent as EmailIcon } from "../../assets/svgs/auth/email.svg";
import { ReactComponent as BoltIcon } from "../../assets/svgs/bolt.svg";

import {
  fetchLoginAppleAction,
  fetchLoginEmailAction,
  fetchLoginGoogleAction,
  initAuthAction,
} from "../../store/auth/actions";
import { authSelector } from "../../store/auth/selector";
import { FlexRow } from "../../components";
import { appleIdTokenParser } from "../../utils";
import { useQuery } from "../../hooks/useQuery";

interface Props {
  setErrorMessage: (msg: string) => void;
  setEmailLogin: (flag: boolean) => void;
}

type TextProps = {} & TypographyProps;

const TextButton = ({ children, sx }: TextProps) => {
  return <Typography sx={{ fontSize: 13.5, fontWeight: 500, ...sx }}>{children}</Typography>;
};

export const LoginButtons = (props: Props) => {
  const { setErrorMessage, setEmailLogin } = props;

  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { openConnectModal } = useConnectModal();
  const { error, user } = useSelector(authSelector);

  useEffect(() => {
    if (appleIdTokenParser(window.location.hash)) {
      setTimeout(() => {
        dispatch(
          fetchLoginAppleAction({
            token: appleIdTokenParser(window.location.hash),
            onboardingCallback: () => {
              navigate("/onboarding");
            },
          })
        );
      }, 1000);
    } else if (!user || !user?.id) {
      dispatch(initAuthAction());
    }
  }, []);

  useEffect(() => {
    if (error && error.message) {
      setErrorMessage(error.message);
    }
  }, [error, navigate]);

  const getButtonStyle = (bgColor: string) => {
    return {
      marginTop: "10px",
      width: "300px",
      height: 40,
      backgroundColor: "transparent",
      borderRadius: "10px",
      justifyContent: "center",
      alignItems: "center",
      border: "1px solid rgba(255, 255, 255, 0.15)",
      cursor: "pointer",
      ":hover": { backgroundColor: bgColor, borderWidth: 0, color: bgColor === "#fff" ? "#000" : "#fff" },
    };
  };

  const getAppleButtonStyle = () => {
    return {
      height: 40,
      width: 320,
      color: "white",
      border: false,
      type: "sign-in", // "continue",
      border_radius: 5,
      scale: 1,
      locale: "en_US",
    };
  };

  return (
    <>
      <GoogleLogin
        clientId="286761658-jb6k2as1kgh9miu810d7gukh7ebp890i.apps.googleusercontent.com"
        render={(renderProps) => (
          <FlexRow sx={getButtonStyle("#fff")} onClick={renderProps.onClick}>
            <GoogleIcon style={{ marginRight: 8, height: 20, width: 20 }} />
            <TextButton>Login with Google</TextButton>
          </FlexRow>
        )}
        buttonText="Signup"
        onSuccess={(response: any) => {
          dispatch(
            fetchLoginGoogleAction({
              response,
              onboardingCallback: () => {
                navigate("/onboarding");
              },
            })
          );
        }}
      />

      <AppleLogin
        clientId="com.boltplus.tv"
        designProp={getAppleButtonStyle()}
        responseType="code id_token"
        responseMode="fragment"
        redirectURI={window.location.origin + window.location.pathname}
        render={(renderProps) => (
          <FlexRow sx={getButtonStyle("#000000")} onClick={renderProps.onClick}>
            <AppleIcon style={{ marginRight: 8, height: 20, width: 20 }} />
            <TextButton>Login with Apple</TextButton>
          </FlexRow>
        )}
      />

      <FlexRow sx={getButtonStyle("#2F80ED")} onClick={openConnectModal}>
        <WalletIcon style={{ marginRight: 8, height: 20, width: 20 }} />
        <TextButton>Login with Wallet</TextButton>
      </FlexRow>

      <FlexRow sx={getButtonStyle(theme.palette.primary.main)} onClick={() => setEmailLogin(true)}>
        <EmailIcon style={{ marginRight: 8, height: 20, width: 20 }} />
        <TextButton>Login with Email</TextButton>
      </FlexRow>
    </>
  );
};
