import React from "react";
import { Box, Tab, TabProps, Tabs, alpha, styled } from "@mui/material";
import { AutoAwesome, ChatBubble, LocalMall, Stars, TextSnippet } from "@mui/icons-material";

import { useSelector } from "react-redux";
import { Chat } from "./chat";
import { Shop } from "./shop";
import { Read } from "./read";
import { Ai } from "./ai";
import { Rewards } from "./rewards";
import { useImmersivePanelContext } from "./ImmersivePanelContext";
import { userSelector } from "../../store/auth/selector";
import { Guest } from "./guest";

export const ImmersivePanelUi = () => {
  const { activeTab, chatProps, updateContext: updateImmersivePanelContext } = useImmersivePanelContext();
  const user = useSelector(userSelector);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    updateImmersivePanelContext({ activeTab: newValue });
  };

  const isProd = process.env.REACT_APP_APP_ENV === "prod";

  return (
    <Box
      sx={{ width: "100%", display: "flex", flexDirection: "column", flex: 1, position: "relative" }}
      className={user?.username === "guest" ? "guest_boxWrapper" : ""}
    >
      <Box
        sx={{
          py: 2,
          width: "100%",
          borderBottom: "1px solid rgba(255, 255, 255, 0.05)",
          position: "sticky",
          top: 0,
          zIndex: 2,
          bgcolor: alpha("#13162C", 0.6),
          backdropFilter: "blur(40px)",
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            minHeight: "unset",

            ".MuiTabs-hideScrollbar": {
              top: `0px !important`,
            },

            ".MuiTabs-flexContainer": {
              display: "block",
            },

            ".MuiTabs-scrollButtons": {
              position: "absolute",
              zIndex: 1,
              width: 25,
              height: 25,
              borderRadius: 25,
              bgcolor: "#3C436F",
              opacity: 1,
              top: "50%",
              transform: "translateY(-50%)",

              "&:first-child": {
                left: 12,
              },

              "&:last-child": {
                right: 12,
              },

              "&.MuiTabScrollButton-root.Mui-disabled": {
                opacity: 0,
              },

              "@media (max-width: 599.95px)": {
                display: "inline-flex",
              },
            },

            ".MuiTab-root": {
              mx: 1,

              "&:first-child": {
                ml: 4,
              },

              "&:last-child": {
                mr: 4,
              },
            },

            ".MuiTabs-indicator": {
              display: "none",
            },
          }}
        >
          <CustomTab label="Chat" icon={<ChatBubble />} />

          {!isProd && <CustomTab label="Shop" icon={<LocalMall />} />}

          {!isProd && <CustomTab label="Read" icon={<TextSnippet />} />}

          <CustomTab label="AI" icon={<AutoAwesome />} />

          {!isProd && <CustomTab label="Rewards" icon={<Stars />} />}
        </Tabs>

        <Box
          sx={{
            position: "absolute",
            top: 0,
            zIndex: 10,
            height: "100%",
            width: 24,
            background: "linear-gradient(90deg, rgba(19,22,44,1) 0%, rgba(19,22,44,0.5) 39%, rgba(255,255,255,0) 100%)",
            pointerEvents: "none",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 10,
            height: "100%",
            width: 24,
            background:
              "linear-gradient(270deg, rgba(19,22,44,1) 0%, rgba(19,22,44,0.5) 39%, rgba(255,255,255,0) 100%)",
            pointerEvents: "none",
          }}
        />
      </Box>

      {activeTab === 0 && <Chat {...chatProps} />}

      {!isProd && activeTab === 1 && <Shop />}

      {!isProd && activeTab === 2 && <Read />}

      {activeTab === (isProd ? 1 : 3) && <Ai />}

      {!isProd && activeTab === 4 && <Rewards />}

      {user?.username === "guest" && <Guest />}
    </Box>
  );
};

const CustomTab = styled((props: TabProps) => <Tab {...props} />)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 600,
  color: "#fff",
  paddingLeft: 12,
  paddingRight: 12,
  paddingTop: 4,
  paddingBottom: 4,
  borderRadius: 6,
  minHeight: 38,
  minWidth: 0,
  flexDirection: "row",
  alignItems: "center",
  columnGap: 8,

  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },

  "&:hover": {
    opacity: 1,
  },

  "&.Mui-selected": {
    backgroundColor: "#FFFFFF",
    color: "#161A2F",
  },

  ".MuiTab-iconWrapper": {
    marginBottom: 0,
  },
}));
