import { Box, Typography } from "@mui/material";
import { QRCode } from "react-qrcode-logo";

import { GooglePayBtn } from "./GooglePayBtn";
import { ApplePayBtn } from "./ApplePayBtn";
import { SubscribeBtn } from "./SubscribeBtn";

export const Subscribe = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3, textAlign: "center", p: 4 }}>
      <Box>
        <Typography sx={{ fontSize: 32, fontWeight: 600 }}>Bloomberg</Typography>

        <Typography sx={{ fontSize: 12, color: "#A3AED0" }}>Get unlimited access today</Typography>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2.25 }}>
        <Typography sx={{ fontSize: 12, color: "#A3AED0" }}>
          Subscribe now for uninterrupted access.
          <br />
          Just £29.99/month. Cancel anytime.
        </Typography>

        <Box sx={{ borderRadius: "10px", overflow: "hidden", lineHeight: 0 }}>
          <QRCode value="https://www.bloomberg.com/subscriptions/uk?utm_medium=cpc_search&utm_campaign=BR_ACQ_BRAND_BRANDGENERALX_EVG_XXXX_XXX_COGBR_EN_EN_X_BLOM_GO_SE_XXX_XXXXXXXXXX" size={150} />
        </Box>

      { /* <Typography sx={{ fontSize: 12, color: "#A3AED0" }}>Scan to pay with Apple Pay or Google Pay</Typography>

        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1.5 }}>
          <ApplePayBtn />

          <GooglePayBtn />
        </Box> */ }
      </Box>
      { /* 
      <Typography sx={{ fontSize: 12, color: "#A3AED0" }}>Or</Typography>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <SubscribeBtn />
      </Box>
      */ }
    </Box>
  );
};
