import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalProps, Typography, Paper, Button, Box, Grid, IconButton, TextField, Fab } from "@mui/material";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import IVSBroadcastClient from "amazon-ivs-web-broadcast";
import { BaseModal } from "./BaseModal";
import CustomSwitch from "../CustomSwitch";
import { FlexRow } from "../FlexRow";
import { ReactComponent as CameraIcon } from "../../assets/svgs/selectCamera.svg";
import { ReactComponent as MicrophoneIcon } from "../../assets/svgs/microphone.svg";
import { ReactComponent as ScreenIcon } from "../../assets/svgs/screen.svg";
import { CustomSelector, Option } from "../CustomSelector";
import { userSelector } from "../../store/auth/selector";
import { userProfileSelector } from "../../store/profile/selector";
import {
  setIsStreamingAction,
  attachPreviewToIvsBroadcastClientAction,
  initIvsBroadcastClientAction,
  setCaptureStreamAction,
  setLayersAction,
  setMixerDevicesAction,
} from "../../store/profile/actions";
import { FlexCol } from "../FlexCol";
import { ReactComponent as CopyIcon } from "../../assets/svgs/copy.svg";
import { ReactComponent as GoToLiveIcon } from "../../assets/svgs/goToLive.svg";
import { useScreenShare, useLayers, useMixer } from "../../hooks/Stream";
import { StreamPreview } from "../StreamPreview";

interface topicType {
  id: string;
  title: string;
}

const overlayPositions = ["TL", "TC", "TR", "CL", "CEN", "CR", "BL", "BC", "BR"];

interface Props extends Partial<ModalProps> {
  open: boolean;
  onBack: () => void;
  onNext: () => void;
}

const CAM_LAYER_NAME = "camera";
const MIC_LAYER_NAME = "mic";

export const StreamDirectlyCameraMicModal = ({ open, onBack, onNext, ...props }: Props) => {
  const dispatch = useDispatch();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const user = useSelector(userSelector);
  const { liveStream, trendingTags } = useSelector(userProfileSelector);
  const [micLevel, setMicLevel] = useState<number>(0);
  const [devicePermissions, setDevicePermissions] = useState({ video: false, audio: false });
  const [activeAudioDeviceId, setActiveAudioDeviceId] = useState<string>();
  const [activeVideoDeviceId, setActiveVideoDeviceId] = useState<string>();
  const [camMuted, setCamMuted] = useState(false);
  const [micMuted, setMicMuted] = useState(false);
  const [activeCameraPosition, setActiveCameraPosition] = useState("BR");
  const [videoOptions, setVideoOptions] = useState<Option[]>([]);
  const [audioOptions, setAudioOptions] = useState<Option[]>([]);
  const { captureStream, startScreenShare, stopScreenShare, updateCameraPosition } = useScreenShare();
  const { layers, updateLayer, addLayer, removeLayer } = useLayers([]);
  const { mixerDevices, addMixerDevice, addAudioTrack, toggleMixerDeviceMute, removeMixerDevice } = useMixer([]);

  let micLevelInterval: any;
  const streamLink = `${document.location.origin}/${user?.username}`;

  const copyLink = () => {
    navigator.clipboard.writeText(streamLink);
  };

  const getTopicIcons = () => {
    const selectedTopics: topicType[] = [];
    trendingTags.forEach((topic: topicType) => {
      user?.streamProfile?.tags?.forEach((tag: string) => {
        if (topic.id === tag) {
          selectedTopics.push(topic);
        }
      });
    });
    return selectedTopics;
  };

  const initStream = async () => {
    dispatch(
      initIvsBroadcastClientAction({
        streamConfig: IVSBroadcastClient.STANDARD_LANDSCAPE,
        ingestEndpoint: user?.streamProfile?.streamEndpoint,
      })
    );
  };

  const getDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((device) => device.kind === "videoinput");
      const videoOptions = videoDevices.map((videoDevice) => {
        const { deviceId, label } = videoDevice;
        return { label, value: deviceId };
      });
      setVideoOptions(videoOptions);
      if (videoOptions.length > 0) {
        setActiveVideoDeviceId(videoOptions[0].value);
      }

      const audioDevices = devices.filter((device) => device.kind === "audioinput");
      const audioOptions = audioDevices.map((audioDevice) => {
        const { deviceId, label } = audioDevice;
        return { label, value: deviceId };
      });
      setAudioOptions(audioOptions);

      if (audioOptions.length > 0) {
        setActiveAudioDeviceId(audioOptions[0].value);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePermissions = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      stream.getTracks().map((track) => track.stop());
      setDevicePermissions({ video: true, audio: true });
      dispatch(attachPreviewToIvsBroadcastClientAction(canvasRef.current));

      getDevices();
    } catch (err: any) {
      setDevicePermissions({ video: false, audio: false });
      console.error(err.message);
    }
  };

  const renderActiveVideoDevice = () => {
    const canvas = liveStream.ivsBroadcastClient?.getCanvasDimensions();
    if (!canvas) {
      return;
    }
    let layer = {
      deviceId: activeVideoDeviceId,
      name: CAM_LAYER_NAME,
      index: 4,
      visible: !camMuted,
      x: 0,
      y: 0,
      width: canvas.width,
      height: canvas.height,
      type: "VIDEO",
    };

    if (captureStream?.active) {
      const padding = 20;
      layer = {
        ...layer,
        x: canvas.width - canvas.width / 4 - padding,
        y: canvas.height - canvas.height / 4 - padding,
        width: canvas.width / 4,
        height: canvas.height / 4,
        type: "VIDEO",
      };
    }

    addLayer(layer, liveStream.ivsBroadcastClient!);
  };

  const microphoneLevelChecking = async () => {
    // if (micLevelInterval) {
    //   clearInterval(micLevelInterval);
    // }
    // const audioStream = await navigator.mediaDevices.getUserMedia({
    //   audio: { deviceId: activeAudioDeviceId },
    // });
    // const audioContext = new AudioContext();
    // const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(audioStream);
    // const analyserNode = audioContext.createAnalyser();
    // mediaStreamAudioSourceNode.connect(analyserNode);
    // const pcmData = new Float32Array(analyserNode.fftSize);
    // const onFrame = () => {
    //   analyserNode.getFloatTimeDomainData(pcmData);
    //   let sumSquares = 0.0;
    //   for (let i = pcmData.length - 1; i >= 0; i -= 1) {
    //     if (pcmData[i] > 0.0) {
    //       sumSquares += pcmData[i];
    //     }
    //   }
    //   setMicLevel(Math.sqrt((sumSquares / pcmData.length) * 10));
    // };
    // micLevelInterval = setInterval(onFrame, 50);
  };

  const toggleMic = async () => {
    const mixerDevice = {
      name: MIC_LAYER_NAME,
      device: activeAudioDeviceId,
      muted: micMuted,
    };

    const muted = toggleMixerDeviceMute(mixerDevice, liveStream.ivsBroadcastClient!);
    if (muted) {
      clearInterval(micLevelInterval);
    } else {
      microphoneLevelChecking();
    }
    setMicMuted(muted);
  };

  const toggleCamera = async () => {
    const canvas = liveStream.ivsBroadcastClient?.getCanvasDimensions();
    if (!canvas) {
      return;
    }

    let camLayer = {
      device: activeVideoDeviceId,
      name: CAM_LAYER_NAME,
      index: 4,
      visible: camMuted,
      x: 0,
      y: 0,
      width: canvas.width,
      height: canvas.height,
      type: "VIDEO",
    };

    if (captureStream?.active) {
      const padding = 20;
      camLayer = {
        ...camLayer,
        x: canvas.width - canvas.width / 4 - padding,
        y: canvas.height - canvas.height / 4 - padding,
        width: canvas.width / 4,
        height: canvas.height / 4,
        type: "VIDEO",
      };
    }

    if (camMuted) {
      await addLayer(camLayer, liveStream.ivsBroadcastClient!);
      setCamMuted(false);
    } else {
      await removeLayer(camLayer, liveStream.ivsBroadcastClient!);
      setCamMuted(true);
    }
  };

  const renderActiveAudioDevice = () => {
    const mixerDevice = {
      name: MIC_LAYER_NAME,
      deviceId: activeAudioDeviceId,
      muted: micMuted,
    };
    addMixerDevice(mixerDevice, liveStream.ivsBroadcastClient!);
  };

  const handleShareScreen = async () => {
    const canvas = liveStream.ivsBroadcastClient?.getCanvasDimensions();
    try {
      if (captureStream?.active) {
        await stopScreenShare(
          activeVideoDeviceId!,
          camMuted,
          removeLayer,
          removeMixerDevice,
          updateLayer,
          liveStream.ivsBroadcastClient!
        );
      } else {
        await startScreenShare(
          activeVideoDeviceId!,
          camMuted,
          updateLayer,
          addLayer,
          removeLayer,
          removeMixerDevice,
          addAudioTrack,
          canvas!,
          liveStream.ivsBroadcastClient!
        );
      }
    } catch (err: any) {
      console.error(`Screen share error: ${err.message}`);
    }
  };

  const changeCameraPosition = (position: string) => {
    const canvas = liveStream.ivsBroadcastClient?.getCanvasDimensions();
    updateCameraPosition(position, activeVideoDeviceId!, false, updateLayer, canvas!, liveStream.ivsBroadcastClient!);
  };

  const startBroadcast = () => {
    liveStream.ivsBroadcastClient
      ?.startBroadcast(user?.streamProfile?.streamKey)
      .then((result: any) => {
        dispatch(setIsStreamingAction(true));
        onNext();
      })
      .catch((error: any) => {
        console.error("Something drastically failed while broadcasting!", error);
      });
  };

  const handleClose = (event: Event, reason: string) => {};

  useEffect(() => {
    initStream();
    handlePermissions();
  }, []);

  useEffect(() => {
    if (activeVideoDeviceId) {
      renderActiveVideoDevice();
    }
  }, [activeVideoDeviceId]);

  useEffect(() => {
    if (activeAudioDeviceId) {
      renderActiveAudioDevice();
      if (!micMuted) microphoneLevelChecking();
    }
  }, [activeAudioDeviceId]);

  useEffect(() => {
    dispatch(setCaptureStreamAction(captureStream));
  }, [captureStream]);

  useEffect(() => {
    dispatch(setLayersAction(layers));
  }, [layers]);

  useEffect(() => {
    dispatch(setMixerDevicesAction(mixerDevices));
  }, [mixerDevices]);

  return (
    <BaseModal
      {...props}
      open={open}
      onClose={handleClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backdropFilter: "blur(7px)",
        px: { xs: 4, md: 0 },
      }}
    >
      <Box
        component={Paper}
        className="-webkit-chatbox-scrollbar"
        sx={{
          py: 2,
          borderRadius: 6,
          position: "relative",
          width: 468,
          height: "90%",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Grid container spacing={2} sx={{ overflow: "auto", flex: 1, px: 2, pt: 1, pb: 4 }}>
          <Grid container item alignItems="center" spacing={1} py={2}>
            <Grid item xs="auto">
              <IconButton
                size="small"
                onClick={() => {
                  if (captureStream?.active) handleShareScreen();
                  if (!camMuted) toggleCamera();
                  if (!micMuted) toggleMic();
                  onBack();
                }}
              >
                <ArrowBackIosNewRoundedIcon sx={{ cursor: "pointer" }} />
              </IconButton>
            </Grid>
            <Grid item xs>
              <Typography
                sx={{
                  color: "#A3AED0",
                  fontWeight: 600,
                  fontSize: { xs: 15, md: 20 },
                  lineHeight: "30px",
                }}
              >
                Create stream
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Fab
                variant="extended"
                sx={{
                  height: 34,
                  px: { xs: 1.5, md: 2.5 },
                  display: "inline-flex",
                  background:
                    "linear-gradient(93.41deg, #BA35E9 -2.97%, #3EA6E9 38.42%, #4382E9 72.03%, #4E33E9 112.38%)",
                }}
                onClick={() => {
                  startBroadcast();
                }}
              >
                <GoToLiveIcon />
                <Typography
                  variant="caption"
                  sx={{ textTransform: "none", fontSize: 14, fontWeight: 600, ml: 1, color: "white" }}
                >
                  Go Live
                </Typography>
              </Fab>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} px={2}>
              <Grid item xs={12}>
                <FlexRow sx={{ gap: 1.5, alignItems: "center" }}>
                  <CameraIcon />
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 12, lineHeight: "18px" }}
                  >
                    Select Camera
                  </Typography>
                </FlexRow>
                <Grid item sx={{ mt: 2, mb: 2, width: "auto" }}>
                  <CustomSelector options={videoOptions} onClick={(option) => setActiveVideoDeviceId(option.value)} />
                </Grid>
              </Grid>

              {captureStream?.active && (
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 11, lineHeight: "18px" }}
                  >
                    Camera Overlay Position
                  </Typography>
                  <FlexRow sx={{ alignItems: "center", gap: 3 }}>
                    <FlexCol sx={{ mt: 2 }}>
                      <FlexRow sx={{ flexWrap: "wrap", mt: 0.5, width: "120px" }}>
                        {overlayPositions.map((overlay, index) => (
                          <FlexRow
                            key={index}
                            sx={{
                              backgroundColor: activeCameraPosition === overlay ? "#282C46" : "#13162C",
                              border: "0.5px solid rgba(255, 255, 255, 0.1)",
                              width: "40px",
                              height: "30px",
                              alignItems: "center",
                              justifyContent: "center",
                              ":hover": {
                                cursor: "pointer",
                              },
                            }}
                            onClick={() => {
                              if (overlay !== activeCameraPosition) {
                                changeCameraPosition(overlay);
                                setActiveCameraPosition(overlay);
                              }
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{
                                color: activeCameraPosition === overlay ? "#fff" : "rgba(163, 174, 208, 0.5)",
                                fontWeight: 400,
                                fontSize: 11,
                              }}
                            >
                              {overlay}
                            </Typography>
                          </FlexRow>
                        ))}
                      </FlexRow>
                    </FlexCol>
                  </FlexRow>
                </Grid>
              )}

              <Grid item xs={12}>
                <FlexRow sx={{ gap: 1.5, alignItems: "center" }}>
                  <MicrophoneIcon />
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 12, lineHeight: "18px" }}
                  >
                    Select Microphone
                  </Typography>
                </FlexRow>
                <Grid item sx={{ mt: 2, mb: 2, width: "auto" }}>
                  <CustomSelector options={audioOptions} onClick={(option) => setActiveAudioDeviceId(option.value)} />
                </Grid>
                {/* <FlexRow sx={{ alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 11, lineHeight: "18px" }}
                >
                  Microphone Level
                </Typography>
                <meter min={0.0} max={1} value={micLevel} style={{ marginLeft: "15px", width: "150px" }} />
              </FlexRow> */}
              </Grid>

              <Grid item xs={12}>
                <FlexRow sx={{ justifyContent: "space-between" }}>
                  <FlexRow sx={{ gap: 1.5, alignItems: "center" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 12, lineHeight: "18px" }}
                    >
                      Mute Mic
                    </Typography>
                  </FlexRow>
                  <CustomSwitch
                    onChange={() => {
                      toggleMic();
                    }}
                  />
                </FlexRow>
              </Grid>

              <Grid item xs={12}>
                <FlexRow sx={{ justifyContent: "space-between" }}>
                  <FlexRow sx={{ gap: 1.5, alignItems: "center" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 12, lineHeight: "18px" }}
                    >
                      Mute Camera
                    </Typography>
                  </FlexRow>
                  <CustomSwitch
                    onChange={() => {
                      toggleCamera();
                    }}
                  />
                </FlexRow>
              </Grid>

              <Grid item xs={12}>
                <FlexRow sx={{ justifyContent: "space-between" }}>
                  <FlexRow sx={{ gap: 1.5, alignItems: "center" }}>
                    <ScreenIcon />
                    <Typography
                      variant="subtitle1"
                      sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 12, lineHeight: "18px" }}
                    >
                      Are you sharing your screen?
                    </Typography>
                  </FlexRow>
                  <CustomSwitch
                    checked={!!captureStream}
                    onChange={() => {
                      handleShareScreen();
                    }}
                  />
                </FlexRow>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#A3AED0", fontWeight: 400, mt: 2, fontSize: 11, lineHeight: "18px" }}
                >
                  Stream Preview
                </Typography>
                <StreamPreview canvasRef={canvasRef} videoPermissions={devicePermissions.video} />
                {/* <canvas ref={canvasRef} style={{ width: "100%", height: "100%", marginTop: 2 }} /> */}
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#fff", fontWeight: 600, mt: 2, letterSpacing: 0.5, fontSize: 20, lineHeight: "18px" }}
                >
                  {user?.streamProfile?.title}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "rgba(255, 255, 255, 0.5)", fontWeight: 400, mt: 1, fontSize: 14, lineHeight: "18px" }}
                >
                  {user?.streamProfile?.description}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <FlexRow sx={{ alignItems: "center", gap: 2 }}>
                  <Grid container sx={{ width: "auto", mt: 2, alignItems: "center" }}>
                    {getTopicIcons().map((item) => (
                      <FlexRow
                        key={item?.id}
                        sx={{
                          textTransform: "none",
                          height: 27,
                          borderRadius: 29 / 2,
                          backgroundColor: "#282C46",
                          px: 2.5,
                          mr: "7.41px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="subtitle1" sx={{ fontWeight: 400, fontSize: 12, lineHeight: 1.5 }}>
                          {item?.title}
                        </Typography>
                      </FlexRow>
                    ))}
                  </Grid>
                </FlexRow>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#A3AED0", fontWeight: 400, fontSize: 12, lineHeight: 1.5, mb: 0.5 }}
                >
                  Live Stream link
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FlexRow sx={{ position: "relative" }}>
                  <TextField
                    name="url"
                    type="url"
                    defaultValue={streamLink}
                    inputProps={{
                      style: {
                        backgroundColor: "#282C46",
                        border: "0.5px solid rgba(255, 255, 255, 0.15)",
                        fontWeight: 400,
                        fontSize: 14,
                        lineHeight: "21px",
                        height: "20px",
                        padding: "7px",
                        paddingLeft: "13px",
                        paddingRight: "80px",
                        borderRadius: "6px",
                      },
                    }}
                    fullWidth
                  />
                  <FlexRow sx={{ alignItems: "center" }}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "rgba(60, 67, 111, 1)",
                        ":hover": { backgroundColor: "rgba(0, 0, 0, 0.2)" },
                        position: "absolute",
                        right: 4,
                        height: "23.57px",
                      }}
                      onClick={copyLink}
                    >
                      <CopyIcon />
                      <Typography sx={{ fontWeight: 400, fontSize: 7, lineHeight: "10.5px", ml: 1 }}>COPY</Typography>
                    </Button>
                  </FlexRow>
                </FlexRow>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </BaseModal>
  );
};
