import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Link } from "react-router-dom";

import { ReactComponent as Heart } from "../assets/svgs/heart2.svg";
import { ReactComponent as CommentIcon } from "../assets/svgs/comment.svg";
import { ReactComponent as Repost } from "../assets/svgs/repost.svg";
import {
  BINANCE_TRANSACTION_STATUS,
  DIRECT_TO_TYPE,
  NFTFeed,
  RECEIVER_TYPE,
  ReactionType,
  STRIPE_TRANSACTION_STATUS,
  StreamFeed,
} from "../types";
import BoltsReceived from "./BoltsReceived";
import CustomIconButton from "./CustomIconButton";
import ReactionButtons from "./ReactionButtons";
import ReactionPopover from "./ReactionPopover";
import FeedContentTop from "./FeedContentTop";
import { FlexRow } from "./FlexRow";
import { capitalizeFirstCharacter, reactionToRender } from "../helpers";
import { updateBinancePayStatus, updateStripePayStatus } from "../store/payment/actions";
import { addStreamFeedReaction, removeStreamFeedReaction } from "../store/feed/actions";
import { addStreamIndividualFeedReaction, removeStreamIndividualFeedReaction } from "../store/feedDetails/actions";
import { ReboltModal } from "./modals";
import { BaseModal } from "./modals/BaseModal";
import GiftBoltsModal from "./GiftBoltsModal";
import { userSelector } from "../store/auth/selector";

type Props = {
  item: StreamFeed | NFTFeed;
  disabled?: boolean;
  disableExpandView?: boolean;
  home?: boolean;
  excludeMedia?: boolean;
  streamerId?: string;
};

const FeedContent = ({
  item,
  disabled = false,
  disableExpandView = false,
  home,
  excludeMedia,
  streamerId = "",
}: Props) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [showReboltModal, setShowReboltModal] = useState(false);
  const [showGiftBox, setShowGiftBox] = useState(false);
  const user = useSelector(userSelector);

  const handleReaction = useCallback(
    (reaction: ReactionType["type"]) => {
      if (home || excludeMedia) {
        dispatch(
          addStreamFeedReaction({
            streamerId,
            streamFeedId: item.reboltFeed?.id || item.id,
            reaction,
          })
        );
      }
      if (!home || excludeMedia) {
        dispatch(
          addStreamIndividualFeedReaction({
            streamFeedId: item.reboltFeed?.id || item.id,
            reaction,
            rebolt: !!item.reboltFeed,
            noApiCall: excludeMedia,
          })
        );
      }
    },
    [dispatch, item, excludeMedia]
  );

  const handleShowReaction = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }, []);

  const handleRemoveReaction = useCallback(
    (reaction: ReactionType["type"]) => {
      if (home || excludeMedia) {
        dispatch(
          removeStreamFeedReaction({
            streamerId,
            streamFeedId: item.reboltFeed?.id || item.id,
            reaction,
          })
        );
      }
      if (!home || excludeMedia) {
        dispatch(
          removeStreamIndividualFeedReaction({
            streamFeedId: item.reboltFeed?.id || item.id,
            reaction,
            rebolt: !!item.reboltFeed?.id,
            noApiCall: excludeMedia,
          })
        );
      }
    },
    [dispatch, item]
  );

  const handleModalClose = (event: any, reason: string) => {
    setShowReboltModal(false);
  };

  const handleGiftPopupClose = () => {
    setShowGiftBox(false);
    dispatch(updateBinancePayStatus(BINANCE_TRANSACTION_STATUS.NOT_INITILIZED));
    dispatch(updateStripePayStatus(STRIPE_TRANSACTION_STATUS.NOT_INITILIZED));
  };

  const reactionRemoveClickCallback = useCallback(
    (reactedTo: any) => (e: any) => {
      e.stopPropagation();
      if (reactedTo) {
        handleRemoveReaction(reactedTo.type);
      }
    },
    [handleRemoveReaction]
  );

  let reactedTo = item.reactions?.find((reactionItem) => reactionItem.reacted);

  if (item.reboltFeed) {
    reactedTo = item.reboltFeed.reactions?.find((reactionItem) => reactionItem.reacted);
  }

  const showMetricPanel = item?.reactionCount || item?.boltCount || item?.commentCount || item?.reboltCount;

  return (
    <>
      <FeedContentTop {...{ item, home, disabled, excludeMedia }} />
      {item.reboltFeed && (
        <Box
          sx={{
            border: "1px solid rgba(255,255,255,0.1)",
            borderRadius: "5px",
            m: { xs: 1.75, sm: 2.5 },
          }}
        >
          <FeedContentTop {...{ item, home, disableExpandView, rebolt: true }} />
        </Box>
      )}
      {!!item?.rewind?.id && (
        <FlexRow sx={{ justifyContent: "center", alignItems: "center", py: 1 }} onClick={(e) => e.stopPropagation()}>
          <Link to={`/${item?.creator?.username}?rewind=${item?.rewind?.id}`}>
            <Button
              color="darker"
              variant="contained"
              sx={{ bgcolor: "white", color: "black", borderRadius: 30, fontWeight: 600, fontSize: 12 }}
              endIcon={<ArrowOutwardIcon />}
            >
              Watch now
            </Button>
          </Link>
        </FlexRow>
      )}
      {!excludeMedia && !!showMetricPanel && (
        <FlexRow sx={{ px: 1.875, py: 1.25, justifyContent: "space-between" }}>
          <ReactionButtons
            {...{
              reactions: item.reboltFeed?.reactions || item.reactions,
              reactionCount: item.reboltFeed?.reactionCount || item.reactionCount,
              streamFeedId: item.reboltFeed?.id || item.id,
              replyCount: item.reboltFeed?.commentCount || item.commentCount,
              reboltCount: item.reboltFeed?.reboltCount || item.reboltCount,
            }}
          />
          <BoltsReceived
            {...{
              boltCount: item?.reboltFeed?.boltCount || item.boltCount,
              bolts: item?.reboltFeed?.bolts || item.bolts,
            }}
          />
        </FlexRow>
      )}

      <FlexRow
        sx={{
          justifyContent: "space-around",
          alignItems: "stretch",
          // gap: 12,
          py: 0.5,
          mb: 1.25,
          borderTop: "0.25px solid rgba(255, 255, 255, 0.05)",
          borderBottom: home ? undefined : "0.25px solid rgba(255, 255, 255, 0.05)",
          overflow: "scroll",
          position: "relative",
          scrollbarWidth: "none",
        }}
      >
        {!!reactedTo && (
          <CustomIconButton
            label={capitalizeFirstCharacter(reactedTo.type)}
            textVibrant
            onClick={reactionRemoveClickCallback(reactedTo)}
          >
            <span style={{ fontSize: 20, lineHeight: "100%" }}>{reactionToRender(reactedTo.type)}</span>
          </CustomIconButton>
        )}
        {!reactedTo && (
          <CustomIconButton label="React" onClick={handleShowReaction}>
            <Heart />
          </CustomIconButton>
        )}
        <ReactionPopover {...{ anchorEl, setAnchorEl, handleReaction }} />
        <CustomIconButton label="Reply">
          <CommentIcon />
        </CustomIconButton>
        <CustomIconButton
          label="Donate"
          onClick={(e) => {
            e.stopPropagation();
            setShowGiftBox(true);
            dispatch(updateBinancePayStatus(BINANCE_TRANSACTION_STATUS.NOT_INITILIZED));
            dispatch(updateStripePayStatus(STRIPE_TRANSACTION_STATUS.NOT_INITILIZED));
          }}
          disabled={disabled}
        >
          $
        </CustomIconButton>
        <CustomIconButton
          label="Rebolt"
          onClick={(e) => {
            e.stopPropagation();
            setShowReboltModal(true);
          }}
          disabled={disabled}
        >
          <Repost height={20} width={20} />
        </CustomIconButton>

        {user?.username === "guest" && <div className="guest_overlayBg" />}
      </FlexRow>
      <ReboltModal item={item} open={showReboltModal} onClose={handleModalClose} />
      <BaseModal open={showGiftBox} sx={{}} onClick={(e) => e.stopPropagation()}>
        <Box sx={{ position: "relative", minWidth: 520 }}>
          <GiftBoltsModal
            open={showGiftBox}
            onClose={handleGiftPopupClose}
            receiverId={item.reboltFeed?.creator?.id || item?.creator?.id}
            receiverName={item.reboltFeed?.creator.username || item.creator.username}
            receiverType={RECEIVER_TYPE.USER}
            directTo={item.reboltFeed?.id || item.id}
            directToType={DIRECT_TO_TYPE.STREAM_FEED}
          />
        </Box>
      </BaseModal>
    </>
  );
};

export default FeedContent;
