import { useEffect, useState } from "react";
import { Box, SxProps, Typography, alpha } from "@mui/material";
import { QRCode } from "react-qrcode-logo";

import { HooraySvg } from "./HooraySvg";
import { ClaimBtn } from "./ClaimBtn";

export type RewardsProps = {
  containerSx?: SxProps;
};

export const Rewards = ({ containerSx = {} }: RewardsProps) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, []);

  if (loading) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 6,
        textAlign: "center",
        p: 4,
        ...containerSx,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1.25 }}>
        <HooraySvg />

        <Typography sx={{ fontSize: 24, fontWeight: 600 }}>Hooray!</Typography>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 3 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography sx={{ fontSize: 14, color: "#A3AED0" }}>You have earned</Typography>

          <Typography sx={{ fontSize: 36, fontWeight: 600, lineHeight: 1 }}>100 TOKENS</Typography>

          <Typography sx={{ fontSize: 14, color: "#A3AED0" }}>
          for participating in this interactive ad, 
            <br />
            Scan to engage
          </Typography>
        </Box>

{ /* 
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <ClaimBtn />

          <Typography>or</Typography>
        </Box>

        */ }
 
        <Box sx={{ borderRadius: "10px", overflow: "hidden", lineHeight: 0 }}>
          <QRCode value="https://immads.boltplus.tv/bloomberg" size={125} />
        </Box>

        <Typography sx={{ fontSize: 14, color: alpha("#ffffff", 0.85) }}>Scan to collect your rewards</Typography>
      </Box>
    </Box>
  );
};
