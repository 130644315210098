import React, { useEffect } from "react";
import { Grid, MenuItem, Paper, Typography, Box, Fab, IconButton, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { profileSelector } from "../../../../store/profile/selector";
import { userSelector } from "../../../../store/auth/selector";
import { ReactComponent as GoToLiveIcon } from "../../../../assets/svgs/goToLive.svg";
import { ReactComponent as StreamClientIcon } from "../../../../assets/svgs/streamClient.svg";
import { StreamClient } from "../../../StreamClient";
import { StreamDirectly } from "../../../StreamDirectly";

export const StreamButton = () => {
  const theme = useTheme();

  const [showStreamMenu, setShowStreamMenu] = React.useState(false);
  const [showStreamClientModal, setShowStreamClientModal] = React.useState(false);
  const [showStreamDirectlyModal, setShowStreamDirectlyModal] = React.useState(false);
  const streamMenuRef = React.useRef(null);
  const user = useSelector(userSelector);
  const { liveStream } = useSelector(profileSelector);
  const navigate = useNavigate();
  const params = useParams();

  const handleClickOutside = (e: any) => {
    if (streamMenuRef.current) {
      // @ts-ignore
      if (!streamMenuRef.current.contains(e.target)) {
        setShowStreamMenu(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      {user?.streamProfile && !liveStream.isStreaming && (
        <Box sx={{ position: "relative", textAlign: "center" }}>
          <IconButton
            sx={{
              width: 36,
              height: 36,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: theme.palette.error.main,

              "&:hover": {
                bgcolor: theme.palette.error.light,
              },
            }}
            onClick={() => setShowStreamMenu(true)}
          >
            <GoToLiveIcon />
          </IconButton>

          {showStreamMenu && (
            <Grid
              container
              component={Paper}
              // @ts-ignore
              sx={{
                p: 1,
                justifyContent: "center",
                borderRadius: 4,
                width: { sm: 225, xs: 225 },
                position: "absolute",
                top: 50,
                left: `calc(40% - 112px)`,
              }}
              ref={streamMenuRef}
            >
              <Grid item>
                <MenuItem
                  sx={{
                    display: "flex",
                    borderRadius: 2,
                    py: 1.5,
                    my: 0.5,
                    mx: { xs: 0.5, sm: 0 },
                    borderStyle: "solid",
                  }}
                  onClick={() => {
                    navigate(`/${user?.username}`);
                    setShowStreamDirectlyModal(true);
                  }}
                >
                  <GoToLiveIcon />
                  <Typography variant="caption" sx={{ fontSize: 11, ml: 3 }}>
                    Stream directly
                  </Typography>
                </MenuItem>
                <MenuItem
                  sx={{
                    display: "flex",
                    borderRadius: 2,
                    py: 1.5,
                    my: 0.5,
                    mx: { xs: 0.5, sm: 0 },
                    borderStyle: "solid",
                  }}
                  onClick={() => setShowStreamClientModal(true)}
                >
                  <StreamClientIcon />
                  <Typography variant="caption" sx={{ fontSize: 11, ml: 2 }}>
                    Stream through client
                  </Typography>
                </MenuItem>
              </Grid>
            </Grid>
          )}
          <StreamClient
            open={showStreamClientModal}
            onClose={() => setShowStreamClientModal(false)}
            onShow={() => setShowStreamClientModal(true)}
          />
          {showStreamDirectlyModal && <StreamDirectly onClose={() => setShowStreamDirectlyModal(false)} />}
        </Box>
      )}

      {!user?.streamProfile && params?.id !== user?.username && (
        <Fab
          variant="extended"
          sx={{
            height: 34,
            mr: { xs: 1, sm: 2 },
            px: { xs: 1, sm: 2.5 },
            background: "linear-gradient(93.41deg, #BA35E9 -2.97%, #3EA6E9 38.42%, #4382E9 72.03%, #4E33E9 112.38%)",
          }}
          onClick={() => navigate(`/${user?.username}`)}
        >
          <GoToLiveIcon />

          <Typography
            variant="caption"
            sx={{ textTransform: "none", fontSize: { sm: 8, lg: 10, xl: 14 }, fontWeight: 600, ml: 1, color: "white" }}
          >
            Apply Stream
          </Typography>
        </Fab>
      )}
    </>
  );
};
