import { useState } from "react";
import { AmazonIVSBroadcastClient, CanvasDimensions } from "amazon-ivs-web-broadcast";
import { Layer } from "./useLayers";
import { AudioDevice } from "./useMixer";

const SCREENSHARE_LAYER_NAME = "screen";
const SCREENSHARE_MIXER_NAME = "screen-audio";
const CAM_LAYER_NAME = "camera";
const CAM_PADDING = 20;

const useScreenShare = () => {
  const [captureStream, setCaptureStream] = useState<MediaStream>();

  const getCaptureStream = async () => {
    try {
      const captureStream = await navigator.mediaDevices.getDisplayMedia({
        video: {
          frameRate: 30,
        },
        audio: {
          echoCancellation: false,
          noiseSuppression: false,
          autoGainControl: false,
        },
      });
      return captureStream;
    } catch (err) {
      return err;
    }
  };

  const startAudioShare = async (
    screenCaptureStreeam: MediaStream,
    addAudioTrack: (audioLayer: AudioDevice, client: AmazonIVSBroadcastClient) => void,
    client: AmazonIVSBroadcastClient
  ) => {
    const [screenAudioTrack] = screenCaptureStreeam.getAudioTracks();
    if (screenAudioTrack) {
      const audioLayer = {
        name: SCREENSHARE_MIXER_NAME,
        track: screenCaptureStreeam,
        muted: screenAudioTrack.muted || false,
      };
      addAudioTrack(audioLayer, client);
    }
  };

  const renderScreenShare = (
    screenCaptureStream: MediaStream,
    activeVideoDeviceId: string,
    camMuted: boolean,
    updateLayer: (layer: Layer, client: AmazonIVSBroadcastClient) => void,
    addLayer: (layer: Layer, client: AmazonIVSBroadcastClient) => void,
    client: AmazonIVSBroadcastClient,
    canvas: CanvasDimensions
  ) => {
    const layer = {
      stream: screenCaptureStream,
      name: SCREENSHARE_LAYER_NAME,
      index: 3,
      visible: true,
      type: "SCREENSHARE",
    };

    const camLayer: Layer = {
      deviceId: activeVideoDeviceId,
      name: CAM_LAYER_NAME,
      index: 4,
      visible: camMuted,
      x: canvas.width - canvas.width / 4 - CAM_PADDING,
      y: canvas.height - canvas.height / 4 - CAM_PADDING,
      width: canvas.width / 4,
      height: canvas.height / 4,
      type: "VIDEO",
    };

    updateLayer(camLayer, client);
    addLayer(layer, client);
  };

  const stopScreenShare = async (
    activeVideoDeviceId: string,
    camMuted: boolean,
    removeLayer: (layer: Layer, client: AmazonIVSBroadcastClient) => void,
    removeMixerDevice: (mixerDevice: AudioDevice, client: AmazonIVSBroadcastClient) => void,
    updateLayer: (layer: Layer, client: AmazonIVSBroadcastClient) => void,
    client: AmazonIVSBroadcastClient
  ) => {
    // Stop screensharing
    if (captureStream?.getTracks()) {
      captureStream?.getTracks().forEach((track) => {
        track.stop();
      });
    }

    // End screensharing
    removeLayer({ name: SCREENSHARE_LAYER_NAME, type: "SCREENSHARE", index: 0 }, client);

    // Remove screenshare audio from the mixer, if it exists
    removeMixerDevice({ name: SCREENSHARE_MIXER_NAME }, client);

    // Move the camera back into the original position
    const camLayer = {
      deviceId: activeVideoDeviceId,
      name: CAM_LAYER_NAME,
      index: 4,
      visible: camMuted,
      type: "VIDEO",
    };
    updateLayer(camLayer, client);
    setCaptureStream(undefined);
  };

  // Function to get the captureStream from the browser
  const startScreenShare = async (
    activeVideoDeviceId: string,
    camMuted: boolean,
    updateLayer: (layer: Layer, client: AmazonIVSBroadcastClient) => void,
    addLayer: (layer: Layer, client: AmazonIVSBroadcastClient) => void,
    removeLayer: (layer: Layer, client: AmazonIVSBroadcastClient) => void,
    removeMixerDevice: (mixerDevice: AudioDevice, client: AmazonIVSBroadcastClient) => void,
    addAudioTrack: (audioLayer: AudioDevice, client: AmazonIVSBroadcastClient) => void,
    canvas: CanvasDimensions,
    client: AmazonIVSBroadcastClient
  ) => {
    try {
      const screenCaptureStream = (await getCaptureStream()) as MediaStream;
      if (screenCaptureStream) {
        const [screenTrack] = screenCaptureStream.getVideoTracks();

        screenTrack.onended = async () => {
          stopScreenShare(activeVideoDeviceId, camMuted, removeLayer, removeMixerDevice, updateLayer, client);
        };

        renderScreenShare(screenCaptureStream, activeVideoDeviceId, camMuted, updateLayer, addLayer, client, canvas);

        startAudioShare(screenCaptureStream, addAudioTrack, client);
        setCaptureStream(screenCaptureStream);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateCameraPosition = async (
    position: string,
    activeVideoDeviceId: string,
    camMuted: boolean,
    updateLayer: (layer: Layer, client: AmazonIVSBroadcastClient) => void,
    canvas: CanvasDimensions,
    client: AmazonIVSBroadcastClient
  ) => {
    try {
      let x = 0;
      let y = 0;
      switch (position) {
        case "TL":
          x = CAM_PADDING;
          y = CAM_PADDING;
          break;
        case "TC":
          x = canvas.width / 2 - canvas.width / 8;
          y = CAM_PADDING;
          break;
        case "TR":
          x = canvas.width - canvas.width / 4 - CAM_PADDING;
          y = CAM_PADDING;
          break;
        case "CL":
          x = CAM_PADDING;
          y = canvas.height / 2 - canvas.height / 8;
          break;
        case "CEN":
          x = canvas.width / 2 - canvas.width / 8;
          y = canvas.height / 2 - canvas.height / 8;
          break;
        case "CR":
          x = canvas.width - canvas.width / 4 - CAM_PADDING;
          y = canvas.height / 2 - canvas.height / 8;
          break;
        case "BL":
          x = CAM_PADDING;
          y = canvas.height - canvas.height / 4 - CAM_PADDING;
          break;
        case "BC":
          x = canvas.width / 2 - canvas.width / 8;
          y = canvas.height - canvas.height / 4 - CAM_PADDING;
          break;
        case "BR":
          x = canvas.width - canvas.width / 4 - CAM_PADDING;
          y = canvas.height - canvas.height / 4 - CAM_PADDING;
          break;
        default:
          x = canvas.width - canvas.width / 4 - CAM_PADDING;
          y = canvas.height - canvas.height / 4 - CAM_PADDING;
          break;
      }
      const camLayer: Layer = {
        deviceId: activeVideoDeviceId,
        name: CAM_LAYER_NAME,
        index: 4,
        visible: camMuted,
        x,
        y,
        width: canvas.width / 4,
        height: canvas.height / 4,
        type: "VIDEO",
      };
      updateLayer(camLayer, client);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    captureStream,
    startScreenShare,
    stopScreenShare,
    updateCameraPosition,
  };
};

export default useScreenShare;
