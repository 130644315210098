import { ReactNode, useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import {
  Avatar,
  Box,
  Fab,
  Drawer,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { CSSObject, styled, Theme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { streamerLiveSelector } from "../../store/streamer/selector";
import { DefaultAvatar } from "../../components/DefaultAvatar";
import { FlexRow } from "../../components/FlexRow";
import { FlexCol } from "../../components/FlexCol";
import { QuickPostCreateModal } from "../../components/modals";
import { NAV_BAR_HEIGHT } from "../../constants";
import { usePrivateLayoutContext } from "./context";
import { userSelector } from "../../store/auth/selector";

const drawerWidth = 250;

export type MenuItem = {
  label: string;
  icon: ReactNode;
  activeIcon: ReactNode;
  path: string;
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 64,
});

const CustomDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  background: "#13162C",
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

type MenuDrawerProps = {
  isOpen: boolean;
};

export const MenuDrawer = ({ isOpen }: MenuDrawerProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = useSelector(streamerLiveSelector);
  const user = useSelector(userSelector);
  const { updatePrivateLayout } = usePrivateLayoutContext();

  const [showPostModal, setShowPostModal] = useState(false);

  const handleListItemClick = (path: string) => {
    navigate(path);
  };

  const handleDrawerOpen = () => {
    updatePrivateLayout({ leftMenubar__visible: true });
  };

  const handleDrawerClose = () => {
    updatePrivateLayout({ leftMenubar__visible: false });
  };

  return (
    <>
      <CustomDrawer
        variant="permanent"
        open={isOpen}
        onMouseOver={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
        onClick={handleDrawerClose}
        PaperProps={{
          sx: {
            borderRight: `1px solid rgba(255, 255, 255, 0.05)`,
            pt: `${NAV_BAR_HEIGHT}px`,
            overflow: "scroll",
            scrollbarWidth: "none",
          },
        }}
      >
        <Box
          sx={{
            px: isOpen ? 3 : "auto",
            display: data.length > 0 ? "flex" : "none",
            height: 20,
            alignItems: "center",
            mt: 3,
            mb: 1,
          }}
        >
          {isOpen ? (
            <FlexRow sx={{ alignItems: "center" }}>
              <Typography sx={{ fontWeight: 600, lineHeight: 1 }}>Live Now</Typography>
              <FlexCol sx={{ backgroundColor: "#EB5757", width: 10, height: 10, ml: 1, borderRadius: 8 }} />
            </FlexRow>
          ) : (
            <Typography
              sx={{
                backgroundColor: "#EB5757",
                width: 45,
                color: "white",
                mx: "auto",
                borderRadius: 2,
                textAlign: "center",
                fontSize: 12,
              }}
            >
              LIVE
            </Typography>
          )}
        </Box>

        <Box sx={{ pb: 2 }}>
          {data.map(({ photoUrl, boltId }, index) => (
            <ListItem
              key={index}
              sx={{ width: "100%", pt: "7px", pb: 0, px: "10px", "::active": { bgcolor: "#ffffff10" } }}
            >
              <ListItemButton
                selected={location.pathname.endsWith(boltId)}
                onClick={() => handleListItemClick(boltId)}
                sx={{
                  borderRadius: "5px",
                  height: "35px",
                  justifyContent: isOpen ? "initial" : "center",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isOpen ? 1 : "auto",
                    justifyContent: "center",
                    width: 30,
                    height: 30,
                  }}
                >
                  <Avatar
                    src={photoUrl}
                    alt={boltId}
                    sx={{
                      borderColor: "primary.main",
                      borderWidth: 1,
                      borderStyle: "solid",
                      height: 30,
                      width: 30,
                    }}
                    imgProps={{ referrerPolicy: "no-referrer" }}
                  >
                    <DefaultAvatar />
                  </Avatar>
                </ListItemIcon>

                <ListItemText
                  primary={boltId}
                  sx={{
                    opacity: isOpen ? 1 : 0,
                    flex: "initial",
                    fontWeight: location.pathname.endsWith(boltId) ? 700 : 600,
                    fontSize: "12px",
                    lineHeight: "18px",
                    letterSpacing: "-0.004em",
                    color: location.pathname.endsWith(boltId) ? "#FFFFFF" : "rgba(255, 255, 255, 0.85)",
                    "& span": {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            marginTop: "auto",
            marginBottom: "22px",
            justifyContent: "center",
          }}
        >
          <Fab
            variant="extended"
            color="secondary"
            sx={{
              height: "35px",
              background: "#6535E9",
              py: 1.5,
              borderRadius: "22px",
              padding: "5px 15px",
              gap: "5px",
            }}
            onClick={() => setShowPostModal(true)}
            disabled={user?.username === "guest"}
          >
            <AddBoxIcon sx={{ height: "20px", width: "20px" }} />
            {isOpen && (
              <Typography variant="caption" fontSize="14px" fontWeight={600}>
                Create Post
              </Typography>
            )}
          </Fab>
        </Box>
      </CustomDrawer>

      <QuickPostCreateModal open={showPostModal} onClose={() => setShowPostModal(false)} />
    </>
  );
};
