import { useEffect, useState } from "react";
import { Avatar, Box, ButtonBase, Paper, SxProps, Typography, alpha, useTheme } from "@mui/material";
import { Image } from "@mui/icons-material";

export type ReadProps = {
  containerSx?: SxProps;
};

export const Read = ({ containerSx = {} }: ReadProps) => {


  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState<Article[]>([]);
  const [error, setError] = useState([]);



 

  useEffect(() => {
    const feedUrl = 'https://api.rss2json.com/v1/api.json?rss_url=https://feeds.bloomberg.com/business/news.rss';

    fetch(feedUrl)
      .then(response => response.json())
      .then(data => {
        setArticles(data.items);
        setLoading(false);
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return null;
  }

  return (
    <Box sx={{ p: 2.5, display: "flex", flexDirection: "column", gap: 0.5, ...containerSx }}>
      {articles.map((item, index) => (
        <div key={`read-card-${index}`}>
          <ReadCard image={item.thumbnail} title={item.title} description={item.description} link={item.link} />
        </div>
      ))}
    </Box>
  );
};


type Article = {
  thumbnail: string;
  title: string;
  description: string;
  link: string;
};

type ReadCardProps = {
  image?: string;
  title?: string;
  description?: string;
  link?: string;
};

const ReadCard = ({ image = "", title = "", description = "", link = "" }: ReadCardProps) => {
  const theme = useTheme();

  return (
    <ButtonBase 
    component="a"
    href={link}
    target="_blank"
    sx={{ borderRadius: "10px" }}>
      <Paper
        sx={{
          borderRadius: "10px",
          bgcolor: "transparent",
          transition: theme.transitions.create(["background-color", "transform"]),
          "&:hover": {
            bgcolor: "#2C2F42",
          },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", p: 2, gap: 1.25 }}>
          <Avatar src={image} sx={{ width: "100%", height: 224, borderRadius: 0.5 }}>
            {!image && <Image sx={{ fontSize: 40 }} />}
          </Avatar>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5, textAlign: "left" }}>
            <Typography sx={{ fontSize: 20, fontWeight: 600, color: alpha("#ffffff", 0.85) }}>{title}</Typography>

            <Typography sx={{ fontSize: 12, color: alpha("#ffffff", 0.45) }} dangerouslySetInnerHTML={{ __html: description }} />
          </Box>
        </Box>
      </Paper>
    </ButtonBase>
  );
};

const readCards: ReadCardProps[] = [
  {
    image: "",
    title: "Microsoft’s CEO Adds AI for Thailand on Southeast Asia Tour",
    description: "By Patpicha Tanakasempipat and Vlad Savov",
  },
  {
    image: "",
    title: "Microsoft CEO to Meet Indonesian President During Regional Tour",
    description: "By Patpicha Tanakasempipat and Vlad Savov",
  },
];
